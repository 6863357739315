<template>
	<div class="developer">
		<div class="top">
			<div class="title">
				<div>开放平台文档中心</div>
				<div></div>
			</div>
			<div class="search">
				<div></div>
				<div class="inp">
					<div>
						<input type="text" placeholder="输入搜索关键词" />
						<div class="btn">搜索</div>
					</div>
					<div>
						<p>热门搜索:</p>
						<p>点餐</p>
						<p>外卖</p>
						<p>电影票</p>
						<p>肯德基</p>
						<p>花费</p>
					</div>
				</div>
			</div>
		</div>
		<div class="mode">
			<div class="box">
				<div v-for="(i,k) in 1" :class="k==depkey?'color':''" :key="i" @click="Switchdep(k)">
					基础文档
				</div>
				<div :class="depkey==2?'color':''" @click="Switchdep(2)">开放业务</div>
				<div :class="depkey==3?'color':''" @click="Switchdep(3)">运营规范</div>
			</div>
		</div>
		<div class="guide">
			<div class="type">
				<el-col :span="12">
					<el-menu default-active="1-1" class="el-menu-vertical-demo" mode="horizontal">
						<el-submenu index="1">
							<template slot="title">
								<!-- <i class="el-icon-location"></i> -->
								<span>基础文档</span>
							</template>
							<el-menu-item index="1-1">开发入门</el-menu-item>
							<el-menu-item index="1-2">开发文档</el-menu-item>
							<el-menu-item index="1-3">API调用场景</el-menu-item>
							<el-menu-item index="1-4">安全保障</el-menu-item>
							<!-- <el-submenu index="1-1">
								<template slot="title">开发入门</template>
								<el-menu-item index="1-1-1">新手指南</el-menu-item>
								<el-menu-item index="1-1-2">应用说明</el-menu-item>
							</el-submenu>
							<el-submenu index="1-2">
								<template slot="title">开发文档</template>
								<el-menu-item index="1-2-1">API调用指南</el-menu-item>
								<el-menu-item index="1-2-2">SDK使用指南</el-menu-item>
								<el-menu-item index="1-2-3">常用错误码</el-menu-item>
								<el-menu-item index="1-2-4">自定义交互</el-menu-item>
							</el-submenu>
							<el-submenu index="1-3">
								<template slot="title">API调用场景</template>
								<el-menu-item index="1-3-1">话费充值</el-menu-item>
								<el-menu-item index="1-3-2">权益特权</el-menu-item>
								<el-menu-item index="1-3-3">活动获取方式</el-menu-item>
							</el-submenu>
							<el-submenu index="1-4">
								<template slot="title">安全保障</template>
								<el-menu-item index="1-4-1">加密接入流程</el-menu-item>
							</el-submenu> -->
						</el-submenu>
						<el-submenu index="2">
							<template slot="title">其它</template>
							<el-menu-item index="2-1">选项1</el-menu-item>
							<el-menu-item index="2-2">选项2</el-menu-item>
							<el-menu-item index="2-3">选项3</el-menu-item>
							<!-- <el-submenu index="2-4">
						      <template slot="title">选项4</template>
						      <el-menu-item index="2-4-1">选项1</el-menu-item>
						      <el-menu-item index="2-4-2">选项2</el-menu-item>
						      <el-menu-item index="2-4-3">选项3</el-menu-item>
						    </el-submenu> -->
						</el-submenu>
					</el-menu>
				</el-col>
			</div>
			<div class="content">
				<div class="newguide">
					<div>新手指南</div>
					<div>
						<span>更新时间:</span>
						<span>2024-05-01</span>
						<span>12:00:00</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				depkey: 0
			}
		},
		mounted() {
			document.title = '开发者文档-众享数字有限公司';
		},
		methods: {
			Switchdep(key) {
				this.depkey = key
			}
		}
	}
</script>

<style lang="scss">
	.developer {
		width: 100%;
		height: auto;
		margin-top: 80px;

		.el-menu-vertical-demo {
			width: 300px;
			min-height: 60px;

			.el-submenu {
				width: 300px;
				min-height: 60px;
				text-align: left;

				.el-submenu__title {
					width: 300px;
					display: flex;

					i:nth-of-type(1) {
						margin: auto 0px;
					}

					span {
						text-align: left;
						margin-left: 5px;
					}
				}
			}
		}

		.top {
			width: 100%;
			height: 250px;

			.title {
				width: 1280px;
				height: 76px;
				margin: 100px auto;

				div:nth-child(1) {
					width: 424px;
					height: 86px;
					line-height: 110px;
					font-size: 44px;
					color: #1e1e28;
					text-align: center;
					margin: 30px auto;
				}
			}

			.search {
				width: 1280px;
				height: 75px;
				margin: 0 auto;
				margin-top: -15px;

				.inp {
					width: 750px;
					height: 75px;
					margin: 55px auto;

					div:nth-child(1) {
						width: 750px;
						height: 48px;
						overflow: hidden;
						position: relative;
						border: 1px solid #ddd;
						border-radius: 5px;
						overflow: hidden;

						input {
							width: 100%;
							height: 48px;
							padding-left: 15px;
							outline: none;
							border: none;
							text-align: left;
						}

						.btn {
							width: 74px;
							height: 48px;
							position: absolute;
							background-color: #0b4ffc;
							font-size: 22px;
							top: 0;
							right: 0;
							color: #fff;
							line-height: 48px;
							padding-left: 30px;
						}
					}

					div:nth-child(2) {
						width: 750px;
						height: 17px;
						display: flex;

						p {
							width: auto;
							height: 17px;
							margin-top: 10px;
							margin: 5px;
							font-size: 12px;
							color: #000000A6;
						}
					}
				}
			}
		}

		.mode {
			width: 100%;
			height: 70px;
			background-color: #fff;
			margin-top: 45px;

			.box {
				width: 1380px;
				height: 70px;
				display: flex;
				margin: 0 auto;

				div {
					min-width: 136px;
					height: 70px;
					font-size: 15px;
					margin: 0px 10px;
					line-height: 70px;
					font-weight: bold;
				}

				.color {
					color: #0b4ffc;
				}
			}
		}

		.guide {
			width: 1380px;
			min-height: 1000px;
			display: flex;
			justify-content: space-between;
			margin: 20px auto;

			.type {
				width: 300px;
				min-height: 1000px;
				overflow: hidden;
				background-color: #fff;
				border-radius: 5px;

			}

			.content {
				width: 1065px;
				min-height: 1000px;
				background-color: #fff;
				border-radius: 5px;

				.newguide {
					width: 1000px;
					height: 110px;
					margin: 25px auto;
					border-bottom: 2px solid #e3e3e3;
					text-align: left;

					div:nth-child(1) {
						width: 1000px;
						height: 50;
						font-size: 35px;
						font-weight: bold;
						color: #000;
					}

					div:nth-child(2) {
						width: 1000px;
						height: 25px;
						font-size: 14px;
						margin-top: 15px;
						color: #000000A6;

						span {
							margin-right: 8px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 900px) {
		.developer {
			width: 100%;
			height: auto;
			margin-top: 2rem;

			.el-menu-vertical-demo {
				width: 300px;
				min-height: 60px;

				.el-submenu {
					width: 300px;
					min-height: 60px;
					text-align: left;

					.el-submenu__title {
						width: 300px;
						display: flex;

						i:nth-of-type(1) {
							margin: auto 0px;
						}

						span {
							text-align: left;
							margin-left: 5px;
						}
					}
				}
			}

			.top {
				width: 100%;
				height: 9rem;

				.title {
					width: 27rem;
					height: 2rem;
					margin: 3rem auto;
					margin-bottom: 4rem;

					div:nth-child(1) {
						width: 27rem;
						height: 2rem;
						line-height: 5rem;
						font-size: 1.5rem;
						color: #1e1e28;
						text-align: center;
						margin: 1rem auto;
					}
				}

				.search {
					width: 27rem;
					height: 4rem;
					margin: 0 auto;
					margin-top: -1rem;

					.inp {
						width: 20rem;
						height: 2rem;
						margin: 0rem auto;

						div:nth-child(1) {
							width: 20rem;
							height: 2rem;
							overflow: hidden;
							position: relative;
							border: 1px solid #ddd;
							border-radius: 5px;
							overflow: hidden;
							background-color: red;

							input {
								width: 100%;
								height: 2rem;
								padding-left: 1rem;
								outline: none;
								border: none;
								text-align: left;
								font-size: 0.7rem;
							}

							.btn {
								width: 2rem;
								height: 2rem;
								position: absolute;
								background-color: #0b4ffc;
								font-size: 1rem;
								top: 0;
								right: 0;
								color: #fff;
								line-height: 2rem;
								padding-right: 1.5rem;
							}
						}

						div:nth-child(2) {
							width: 20rem;
							height: 1rem;
							display: flex;

							p {
								width: auto;
								height: 1rem;
								margin-top: 0.6rem;
								margin: 0.3rem;
								font-size: 0.7rem;
								color: #000000A6;
							}
						}
					}
				}
			}

			.mode {
				width: 27rem;
				height: 4rem;
				background-color: #fff;
				margin-top: 0rem;
				overflow-x: auto;

				&::-webkit-scrollbar {
					display: none;
					/* 针对Webkit浏览器 */
				}

				.box {
					width: 50rem;
					height: 4rem;
					display: flex;

					div {
						min-width: 6rem;
						height: 4rem;
						font-size: .8rem;
						margin: 0rem 0rem;
						line-height: 4rem;
						font-weight: bold;
					}

					.color {
						color: #0b4ffc;
					}
				}
			}

			.guide {
				width: 27rem;
				min-height: 50rem;
				display: flex;
				justify-content: space-between;
				margin: 1rem auto;

				.type {
					width: 27rem;
					min-height: 5rem;
					overflow: hidden;
					background-color: #fff;
					border-radius: 5px;

					.el-col {
						width: 27rem;
						height: 4rem;

						.el-menu-vertical-demo {
							width: 27rem;
							min-height: 60px;

							.el-submenu {
								width: 8.5rem;
								min-height: 60px;
								text-align: left;

								.el-submenu__title {
									width: 8.5rem;
									display: flex;

									i:nth-of-type(1) {
										margin: auto .1rem;
									}

									span {
										text-align: left;
										margin-left: 5px;
										margin-top: -.1rem;
									}

									i:nth-of-type(2) {
										margin: auto .3rem;
									}
								}
							}
						}
					}
				}

				.content {
					width: 27rem;
					min-height: 50rem;
					background-color: #fff;
					border-radius: .3rem;

					.newguide {
						width: 27rem;
						height: 110px;
						margin: 25px auto;
						border-bottom: 2px solid #e3e3e3;
						text-align: left;

						div:nth-child(1) {
							width: 27rem;
							height: 3rem;
							font-size: 1.5rem;
							font-weight: bold;
							color: #000;
						}

						div:nth-child(2) {
							width: 27rem;
							height: 1.5rem;
							font-size: .9rem;
							margin-top: .8rem;
							color: #000000A6;

							span {
								margin-right: 8px;
							}
						}
					}
				}
			}
		}
	}
</style>