<template>
	<div class="shop">
		<div class="title">
			<div>海量API接口在这里</div>
			<div></div>
		</div>
		<div class="search">
			<div class="inp">
				<div>
					<input type="text" placeholder="输入搜索关键词" />
					<div class="btn">搜索</div>
				</div>
				<div>
					<p>热门搜索:</p>
					<p>点餐</p>
					<p>外卖</p>
					<p>电影票</p>
					<p>肯德基</p>
					<p>花费</p>
				</div>
			</div>
		</div>
		<div class="option">
			<div class="box">
				<div class="toleft" @click="turnLeft">〈</div>
				<div class="type">
					<ul>
						<li v-for="(i,k) in 2" :key="i" :class="k==0?'li_line typeli':'typeli'" @click="switchAPI(k)">
							{{k==0?'全部':'霸王API'}}
						</li>
					</ul>
				</div>
				<div class="toright" @click="turnRight">〉</div>
			</div>
		</div>
		<div class="items">
			<div class="item_tit">霸王餐API</div>
			<div class="item_lst">
				<div v-for="(i,k) in 3" :key="k" class="list">
					<div class="name">
						<i class="el-icon-caret-right"></i>
						<div>美团霸王餐活动列表</div>
						<div>免费</div>
					</div>
					<div class="direction">获取美团官方霸王餐的活动列表</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: []
			}
		},
		created() {
			document.title = 'API市场-众享数字有限公司';
		},
		methods: {
			switchAPI(key) {
				var lis = document.querySelectorAll('.typeli')
				console.log(key, lis[key])
				for (var i = 0; i < lis.length; i++) {
					lis[i].setAttribute('class', 'typeli')
				}
				lis[key].setAttribute('class', 'li_line typeli')
			},
			turnLeft() {
				var boxs = document.querySelector('.option>.box>.type')
				var uls = document.querySelector('.option>.box>.type>ul')
				console.log(uls.offsetWidth, uls.offsetLeft)
				const val = uls.offsetLeft - boxs.offsetWidth
				if (val <= 0) {
					uls.style.left = 0
				} else {
					uls.style.left = val
				}
			},
			turnRight() {
				var boxs = document.querySelector('.option>.box>.type')
				var uls = document.querySelector('.option>.box>.type>ul')
				const val = uls.offsetLeft + boxs.offsetWidth
				console.log(val, uls.offsetLeft, uls.offsetWidth)
				if (uls.offsetWidth <= boxs.offsetWidth) {
					return false
				}
				if (val >= -uls.offsetWidth) {
					uls.style.left = 'unset'
					uls.style.right = '0px'
				} else {
					uls.style.left = -val + 'px'
				}
			},
		}
	}
</script>

<style lang="scss">
	* {
		margin: 0;
		padding: 0;
		list-style: none;
		text-decoration: none;
	}

	.shop {
		width: 1380px;
		height: auto;
		margin-top: 80px;
		margin: 0 auto;

		.title {
			width: 1380px;
			height: 76px;
			margin: 100px auto;

			div:nth-child(1) {
				width: 424px;
				height: 86px;
				line-height: 110px;
				font-size: 44px;
				color: #1e1e28;
				text-align: center;
				margin: 30px auto;
			}
		}

		.search {
			width: 1380px;
			height: 75px;
			margin: 0 auto;
			margin-top: -15px;

			.inp {
				width: 750px;
				height: 75px;
				margin: 55px auto;

				div:nth-child(1) {
					width: 750px;
					height: 48px;
					overflow: hidden;
					position: relative;
					border: 1px solid #ddd;
					border-radius: 5px;
					overflow: hidden;

					input {
						width: 100%;
						height: 48px;
						padding-left: 15px;
						outline: none;
						border: none;
						text-align: left;
					}

					.btn {
						width: 74px;
						height: 48px;
						position: absolute;
						background-color: #0b4ffc;
						font-size: 22px;
						top: 0;
						right: 0;
						color: #fff;
						line-height: 48px;
						padding-left: 30px;
					}
				}

				div:nth-child(2) {
					width: 750px;
					height: 17px;
					display: flex;

					p {
						width: auto;
						height: 17px;
						margin-top: 10px;
						margin: 5px;
						font-size: 12px;
						color: #000000A6;
					}
				}
			}
		}

		.option {
			width: 100%;
			height: 70px;
			margin: 50px auto;
			display: flex;
			justify-content: space-between;
			background-color: #fff;

			.box {
				width: 1420px;
				height: 70px;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;

				.toleft {
					width: 3%;
					height: 70px;
					line-height: 70px;
					font-size: 25px;
				}

				.type {
					width: 94%;
					height: 70px;
					position: relative;
					overflow: hidden;

					ul {
						width: auto;
						height: 70px;
						position: absolute;
						left: 0px;
						display: flex;

						li {
							width: auto;
							height: 70px;
							line-height: 70px;
							margin: 0px 30px;
						}

						.li_line {
							box-sizing: border-box;
							border-bottom: 3px solid #1e1e28;
						}
					}
				}

				.toright {
					width: 3%;
					height: 70px;
					line-height: 70px;
					font-size: 30px;
				}
			}


		}

		.items {
			width: 100%;
			min-height: 425px;
			margin: 0 auto;
			padding-bottom: 20px;

			.item_tit {
				width: 1380px;
				height: 45px;
				color: #1e1e28;
				margin: 20px auto;
				text-align: left;
				font-size: 32px;
			}

			.item_lst {
				width: 1400px;
				min-height: 180px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-start;

				.list {
					width: 440px;
					height: 160px;
					margin: 0px 20px 20px 4px;
					border: 1px solid #ececec;
					background-color: #fff;

					.name {
						width: 348px;
						height: 36px;
						display: flex;
						margin: 20px auto;

						i {
							width: 24px;
							height: 16px;
							color: #0b4ffc;
							font-size: 35px;
							margin: 4px 10px 0px -25px;
							text-align: center;
							line-height: 16.5px;
							overflow: hidden;
						}

						div:nth-of-type(1) {
							font-size: 18px;
							font-weight: bold;
						}

						div:nth-of-type(2) {
							width: 32px;
							height: 17px;
							font-size: 12px;
							color: red;
							text-align: center;
							line-height: 17px;
							border: 1px solid red;
							margin: 3px 0px 0px 10px;
							border-radius: 2px;
							font-weight: bold;
						}
					}

					.direction {
						width: 348px;
						height: 20px;
						font-size: 15px;
						color: #000;
						margin: -25px -38px;
					}
				}

			}
		}
	}

	@media screen and (max-width: 900px) {
		.shop {
			width: 27rem;
			height: auto;
			margin: 2rem auto;

			.title {
				width: 27rem;
				height: 2rem;
				margin: 3rem auto;
				margin-bottom: 4rem;

				div:nth-child(1) {
					width: 27rem;
					height: 2rem;
					line-height: 5rem;
					font-size: 1.5rem;
					color: #1e1e28;
					text-align: center;
					margin: 1rem auto;
				}
			}

			.search {
				width: 27rem;
				height: 4rem;
				margin: 0 auto;
				margin-top: -1rem;

				.inp {
					width: 20rem;
					height: 2rem;
					margin: 0rem auto;

					div:nth-child(1) {
						width: 20rem;
						height: 2rem;
						overflow: hidden;
						position: relative;
						border: 1px solid #ddd;
						border-radius: 5px;
						overflow: hidden;
						background-color: red;

						input {
							width: 100%;
							height: 2rem;
							padding-left: 1rem;
							outline: none;
							border: none;
							text-align: left;
							font-size: 0.7rem;
						}

						.btn {
							width: 2rem;
							height: 2rem;
							position: absolute;
							background-color: #0b4ffc;
							font-size: 1rem;
							top: 0;
							right: 0;
							color: #fff;
							line-height: 2rem;
							padding-right: 1.5rem;
						}
					}

					div:nth-child(2) {
						width: 20rem;
						height: 1rem;
						display: flex;

						p {
							width: auto;
							height: 1rem;
							margin-top: 0.6rem;
							margin: 0.3rem;
							font-size: 0.7rem;
							color: #000000A6;
						}
					}
				}
			}

			.option {
				width: 27rem;
				height: 3rem;
				margin: 2rem auto;
				display: flex;
				justify-content: space-between;
				background-color: #fff;

				.box {
					width: 27rem;
					height: 3rem;
					margin: 0 auto;
					display: flex;
					justify-content: space-between;

					.toleft {
						width: 7%;
						height: 3rem;
						line-height: 3rem;
						font-size: 1.5rem;
					}

					.type {
						width: 86%;
						height: 3rem;
						position: relative;
						overflow-x: auto;

						&::-webkit-scrollbar {
							display: none;
							/* 针对Webkit浏览器 */
						}

						ul {
							width: auto;
							height: 3rem;
							position: absolute;
							left: 0rem;
							display: flex;

							li {
								width: auto;
								height: 3rem;
								line-height: 3rem;
								margin: 0px 1.2rem;
								font-size: .8rem;
							}

							.li_line {
								box-sizing: border-box;
								border-bottom: 3px solid #1e1e28;
							}
						}
					}

					.toright {
						width: 7%;
						height: 3rem;
						line-height: 3rem;
						font-size: 1.5rem;
					}
				}


			}

			.items {
				width: 27rem;
				min-height: auto;
				margin: 2rem auto;
				padding-bottom: 1.5rem;

				.item_tit {
					width: 26rem;
					height: 3rem;
					color: #1e1e28;
					margin: 1rem auto;
					text-align: left;
					font-size: 1.1rem;
					line-height: 3rem;
					padding-left: 1rem;
				}

				.item_lst {
					width: 28rem;
					min-height: 180px;
					margin: -1rem auto;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-start;

					.list {
						width: 25rem;
						height: 8rem;
						margin: 0px 0.75rem .5rem 1rem;
						border: 1px solid #ececec;
						background-color: #fff;

						.name {
							width: 25rem;
							height: 2rem;
							display: flex;
							margin: 1.25rem auto;
							overflow: hidden;

							i {
								width: 1.3rem;
								height: 1rem;
								color: #0b4ffc;
								font-size: 2rem;
								margin: .3rem 0.6rem 0rem 1.3rem;
							}

							div:nth-of-type(1) {
								height: 1rem;
								line-height: 1.7rem;
								font-size: .9rem;
								font-weight: bold;
							}

							div:nth-of-type(2) {
								width: 2rem;
								height: 1rem;
								font-size: 0.7rem;
								color: red;
								text-align: center;
								line-height: 1rem;
								border: 1px solid red;
								margin: .2rem 0rem 0rem .7rem;
								border-radius: 2px;
								font-weight: bold;
							}
						}

						.direction {
							width: 348px;
							height: 20px;
							font-size: .7rem;
							color: #000;
							margin: -1rem -2.8rem;
						}
					}

				}
			}
		}
	}
</style>