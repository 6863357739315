<template>
	<div class="goodsearch">
		<div class="inp">
			<i class="el-icon-search"></i>
			<input type="text" v-model="SearchShop" placeholder="搜索商品" />
			<div @click="SearchName">搜索</div>
		</div>
		<div class="shoplst">
			<div class="shopitem" v-for="(item,index) in SearchList" :key="index" v-if="SearchList.length"
				@click="goDetail(item)">
				<img :src="item.image" alt="" />
				<p>
					<span>￥{{item.price}}</span>
					<span>￥{{item.ot_price}}</span>
				</p>
				<p>{{item.store_name}}</p>
				<p>
					<span>{{item.sales}}人付款</span>
					<span>{{item.star}}分</span>
				</p>
			</div>
			<div class="nothing" v-if="SearchList.length==0">
				<img src="@/static/img/shops.png" alt="" />
				<p>亲，该分类暂无商品呦~</p>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios"
	import store from '../../store.js'
	export default {
		data() {
			return {
				SearchList: [],
				SearchShop: '',
			}
		},
		mounted() {
			this.getSearch()
			document.title = '商品搜索-众享数字有限公司';
		},
		methods: {
			// 头一次加载的数据
			getSearch() {
				const self = this
				self.SearchShop = self.$route.query.keyword
				axios.get(store.state.url + '/api/pc/get_products?keyword=' + this.$route.query.keyword)
					.then(d => {
						self.SearchList = d.data.data.list
					})
					.catch(e => {})
			},
			// 搜索商品
			SearchName() {
				const self = this
				if (this.SearchShop != '') {
					self.$router.push('/goodSearch?keyword=' + self.SearchShop)
					axios.get(store.state.url + '/api/pc/get_products?page=""&limit=""&keyword=' + self.SearchShop)
						.then(d => {
							self.SearchList = d.data.data.list
						})
						.catch(e => {})
				} else {
					this.$message({
						showClose: true,
						message: '请输入要搜索的内容',
						type: 'error'
					});
				}
			},
			goDetail(item) {
				console.log(item.id)
				window.open(store.state.url + '/goods_detail/' + item.id, '_blank')
			},
		}
	}
</script>

<style lang="scss">
	.goodsearch {
		width: 1450px;
		height: auto;
		margin: 100px auto;
		text-align: left;
		padding-top: 30px;


		.inp {
			width: 650px;
			height: 45px;
			border: 1px solid red;
			position: relative;
			display: flex;
			justify-content: space-between;
			border-radius: 3px;
			margin: 0px auto;
			background-color: #fff;

			i {
				font-size: 19px;
				margin: 13px 15px;
			}

			input {
				width: 550px;
				border: none;
				outline: none;
				font-size: 15px;
			}

			div {
				width: 90px;
				height: 45px;
				font-size: 16px;
				background-color: red;
				color: #fff;
				line-height: 50px;
				text-align: center;
			}
		}

		.shoplst {
			width: 1420px;
			height: auto;
			display: flex;
			margin: 0 auto;
			margin-top: 15px;
			flex-wrap: wrap;

			.shopitem {
				width: 250px;
				height: 370px;
				box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
				margin: 50px 34px 20px 0px;
				background-color: #fff;

				img {
					width: 210px;
					height: 210px;
					margin: 10px 20px;
				}

				p:nth-of-type(1) {
					width: 210px;
					height: 45px;
					margin: 0px auto;
					font-size: 16px;
					display: -webkit-box;

					span:nth-child(1) {
						font-size: 22px;
						color: #e10000;
						font-weight: bold;
					}

					span:nth-child(2) {
						font-size: 12px;
						color: #afafaf;
						text-decoration: line-through;
					}
				}

				p:nth-of-type(2) {
					width: 200px;
					height: 45px;
					margin: -10px auto;
					font-size: 16px;
					display: -webkit-box;
					/* 使用弹性盒子布局 */
					-webkit-box-orient: vertical;
					/* 垂直方向排列 */
					-webkit-line-clamp: 2;
					/* 设置显示的行数 */
					overflow: hidden;
					/* 超出部分隐藏 */
				}

				p:nth-of-type(3) {
					width: 210px;
					height: 25px;
					margin: 30px auto;
					display: flex;
					justify-content: space-between;
					font-size: 13px;
					color: #aaa;
				}
			}

			.nothing {
				width: 100%;
				height: 600px;
				text-align: center;
				color: #aaa;

				img {
					margin-top: 100px;
				}
			}
		}
	}
</style>